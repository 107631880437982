import React from "react";
import styled from 'styled-components';

const Image = styled.img`
  position: relative;
  height: 180px;
  width: 180px;
  background: #40378f;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
  display: block;
  border-radius: 50%;
  // border-width: 8px;
  // border-style: solid;
  // border-color: #017CBD;
  // border-color: #353575;
  box-sizing: border-box;
  object-fit: cover;
`;

function Gif(props) {
  return (
    <Image src={props.image}/>
  )
}

export default Gif;