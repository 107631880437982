import React, { useEffect, useState } from 'react';
import styles from '../styles/Home.module.css';
import Gif from '../components/gif';
import Loading from '../components/loading';
import styled from 'styled-components';
import axios from 'axios';

const Card1 = styled.div`
  width: 100%;
  background: #f4eedf;
  padding: 30px 30px 60px 30px;
  border-radius: 3rem 3rem 0 0;
  color: #3b3e30;
  text-align: center;
  margin-bottom: -50px;
`;

const Card2 = styled.div`
  width: 100%;
  background: #8e907c;
  padding: 30px;
  border-radius: 3rem;
  color: #3b3e30;
  text-align: center;
  margin-bottom: -50px;
  z-index: 99;
`;

const Card3 = styled.div`
  width: 100%;
  background: #c9d5dd;
  padding: 90px 30px 60px 30px;
  color: #3b3e30;
  text-align: center;
`;

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [nodata1, setNoData1] = useState(false);
  const [nodata2, setNoData2] = useState(false);
  const [date, setDate] = useState();
  const [dateAG, setDateAG] = useState();
  const [soilpercentage, setSoilpercentage] = useState();
  const [soiltemperature, setSoilTemperature] = useState();
  const [soilpercentageAG, setSoilpercentageAG] = useState();
  const [soiltemperatureAG, setSoilTemperatureAG] = useState();
  const [waterlevel, setWaterLevel] = useState();
  const [waterlevelAG, setWaterLevelAG] = useState();
  const [volume, setVolume] = useState();
  const [volumeAG, setVolumeAG] = useState();
  const [volumebluelay, setVolumeBluelay] = useState();

  const fetchDataWaterLevel = async () => {
    const response = await fetch('https://api.flowfacts.nl/v1/measurements/?client_id=49&node_id=6&maxrows=1', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'FFAPIKEY': 'U5jsIXGr8E71kOdmoqFSKgcWb9pQdyvHFrhQb9KDC0Y5uQcRFhTeVELo47gtLWBa',
        'model': 'FF40-NBIOT-EU',
      },
    })
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }

  const fetchDataWaterLevelAG = async () => {
    const response = await fetch('https://api.flowfacts.nl/v1/measurements/?client_id=49&node_id=7&maxrows=1', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'FFAPIKEY': 'U5jsIXGr8E71kOdmoqFSKgcWb9pQdyvHFrhQb9KDC0Y5uQcRFhTeVELo47gtLWBa',
        'model': 'FF40-NBIOT-EU',
      },
    })
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }

  const fetchDataSoilMoisture = async () => {
    const response = await fetch('https://api.flowfacts.nl/v1/measurements/?client_id=49&node_id=4&maxrows=1', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'FFAPIKEY': 'U5jsIXGr8E71kOdmoqFSKgcWb9pQdyvHFrhQb9KDC0Y5uQcRFhTeVELo47gtLWBa',
        'model': 'FF13-868-EU',
      },
    })
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }

  const fetchDataSoilMoistureAG = async () => {
    const response = await fetch('https://api.flowfacts.nl/v1/measurements/?client_id=49&node_id=3&maxrows=1', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'FFAPIKEY': 'U5jsIXGr8E71kOdmoqFSKgcWb9pQdyvHFrhQb9KDC0Y5uQcRFhTeVELo47gtLWBa',
        'model': 'FF13-868-EU',
      },
    })
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
      return response.json()
    }
  }
  
  // const getData = async () => {
  //   console.log('refresh data');
  //   try {
  //     setInterval(async () => {
        
  //     }, 3600000);
  //   } catch(e) {
  //     console.log(e);
  //   }
  // }

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchDataWaterLevel()
    .then((res) => {
      setNoData1(false);
      const date = new Date(res.dt * 1000);
      setWaterLevel(res.data[0][1]);
      let vol = (res.data[0][1] * 2.1 * 5.5); // calculate Liters = amount mm x m2
      vol = (Math.round(10 * vol) ) / 10;
      setVolume(vol);
      setDate(date.toLocaleString());
      setLoading(false)
    })
    .catch((e) => {
      console.log('error fetchDataWaterLevel', e.message);
      setNoData1(true);
      setLoading(false);
    })

    fetchDataWaterLevelAG()
    .then((resAG) => {
      setNoData2(false);
      const date = new Date(resAG.dt * 1000);
      setWaterLevelAG(resAG.data[0][1]);
      let vol = (resAG.data[0][1] * 2.1 * 5.5); // calculate Liters = amount mm x m2
      vol = (Math.round(10 * vol) ) / 10;
      setVolumeAG(vol);
      setDateAG(date.toLocaleString());
      setLoading(false)
    })
    .catch((e) => {
      console.log('error fetchDataWaterLevelAG', e.message);
      setNoData2(true);
      setLoading(false);
    })

    fetchDataSoilMoisture()
    .then((resSoil) => {
      setSoilTemperature((resSoil.data[0][2]).toString().replace(/\./g,','));
      const raw = resSoil.data[0][3];
      const vwcSoilPercentage = raw.toString().replace(/\./g,',');
      console.log('vwcSoilPercentage', vwcSoilPercentage);
      setSoilpercentage(vwcSoilPercentage);
    })
    .catch((e) => {
      console.log(e.message);
      setLoading(false);
    })

    fetchDataSoilMoistureAG()
    .then((resSoilAG) => {
      setSoilTemperatureAG((resSoilAG.data[0][2]).toString().replace(/\./g,','));
      const rawAG = resSoilAG.data[0][3];
      let volbluelay = ((rawAG/100)*4.2*2.1*20); //oppervlakte (4.2x2.1) X 20L per m2 (voor bluelay 23mm))
      volbluelay = (Math.round(10 * volbluelay) ) / 10;
      const vwcSoilPercentageAG = rawAG.toString().replace(/\./g,',');
      console.log('vwcSoilPercentage', vwcSoilPercentageAG);
      setVolumeBluelay(volbluelay);
      setSoilpercentageAG(vwcSoilPercentageAG);
    })
    .catch((e) => {
      console.log(e.message);
      setLoading(false);
    })

    setTimeout(() => {
      fetchDataWaterLevel()
      .then((res) => {
        setNoData1(false);
        const date = new Date(res.dt * 1000);
        setWaterLevel(res.data[0][1]);
        let vol = (res.data[0][1] * 2.1 * 5.5); // calculate Liters = amount mm x m2
        vol = (Math.round(10 * vol) ) / 10;
        setVolume(vol);
        setDate(date.toLocaleString());
        setLoading(false)
      })
      .catch((e) => {
        console.log('error fetchDataWaterLevel', e.message);
        setNoData1(true);
        setLoading(false);
      })

      fetchDataWaterLevelAG()
      .then((resAG) => {
        setNoData2(false);
        const date = new Date(resAG.dt * 1000);
        setWaterLevelAG(resAG.data[0][1]);
        let vol = (resAG.data[0][1] * 2.1 * 5.5); // calculate Liters = amount mm x m2
        vol = (Math.round(10 * vol) ) / 10;
        setVolumeAG(vol);
        setDateAG(date.toLocaleString());
        setLoading(false)
      })
      .catch((e) => {
        console.log('error fetchDataWaterLevelAG', e.message);
        setNoData2(true);
        setLoading(false);
      })

      fetchDataSoilMoisture()
      .then((resSoil) => {
        setSoilTemperature((resSoil.data[0][2]).toString().replace(/\./g,','));
        const raw = resSoil.data[0][3];
        const vwcSoilPercentage = raw.toString().replace(/\./g,',');
        console.log('vwcSoilPercentage', vwcSoilPercentage);
        setSoilpercentage(vwcSoilPercentage);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      })

      fetchDataSoilMoistureAG()
      .then((resSoilAG) => {
        setSoilTemperatureAG((resSoilAG.data[0][2]).toString().replace(/\./g,','));
        const rawAG = resSoilAG.data[0][3];
        let volbluelay = ((rawAG/100)*4.2*2.1*20); //oppervlakte (4.2x2.1) X 20L per m2 (voor bluelay 23mm))
        volbluelay = (Math.round(10 * volbluelay) ) / 10;
        const vwcSoilPercentageAG = rawAG.toString().replace(/\./g,',');
        console.log('vwcSoilPercentage', vwcSoilPercentageAG);
        setVolumeBluelay(volbluelay);
        setSoilpercentageAG(vwcSoilPercentageAG);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      })
    }, 3600000);

  
    if (waterlevelAG && volumeAG && volumebluelay && soilpercentageAG && soiltemperatureAG && dateAG && waterlevel && volume && soilpercentage && soiltemperature && date){
      let volumeTerras = volumeAG + volumebluelay;
      volumeTerras = (Math.round(10 * volumeTerras) ) / 10;

      const data1={
        tmillimeters: waterlevelAG,
        tliters: volumeTerras,
        tbodemvocht: soilpercentageAG,
        tbodemtemperatuur: soiltemperatureAG,
        tdate: dateAG,
        mmillimeters: waterlevel,
        mliters: volume,
        mbodemvocht: soilpercentage,
        mbodemtemperatuur: soiltemperature,
        mdate: date,
      }

      axios.put('https://sheet.best/api/sheets/970689e1-343e-4d54-8bc3-8c5396db46ce/0',data1).then((response)=>{
        console.log('googlesheet response1', response);
      })
    }

  }, [waterlevelAG, volumeAG, soilpercentageAG, volumebluelay, waterlevel, volume, soilpercentage, soiltemperature, soiltemperatureAG, dateAG, date]);
  
  return (
    <>
      {loading ? (
        <main className={styles.mainLoad}>
          <Loading />
        </main>
      ) : (
        <>
          <main className={styles.main}>
            {/* <button onClick={getData}>ververs data</button><br/> */}
            <Card1>
              <Gif image='moestuin.jpg'/>
              <h2 className={styles.title}>
                De moestuin 
              </h2>
              {nodata1 ? (
                <p className={styles.description}>
                  Waterniveau: <b>geen informatie</b>
                </p>
              ) : (
                <p className={styles.description}>
                  Waterniveau: <b>{waterlevel} mm / {volume} Liter</b>
                </p>
              )}
              <p className={styles.description}>
                Bodemvocht: <b>{soilpercentage}%</b><br/>
                Bodemtemperatuur: <b>{soiltemperature} °</b>  Celsius.
              </p>
              <p style={{fontSize: 12}}>Laatste waarneming: {date}</p>
            </Card1>
            <Card2>
              <Gif image='terras.jpg'/>
              <h2 className={styles.title}>
                Het terras 
              </h2>
              {nodata2 ? (
                <p className={styles.description}>
                  Waterniveau: <b>geen informatie</b>
                </p>
              ) : (
                <p className={styles.description}>
                  Waterniveau: <b>{waterlevelAG} mm / {volumeAG} Liter</b>
                </p>
              )}
              <p className={styles.description}>
                Bluelay: <b>{soilpercentageAG}% / {volumebluelay} Liter</b><br/>
                Temperatuur Bluelay: <b>{soiltemperatureAG} °</b>  Celsius.
              </p>
              <p style={{fontSize: 12}}>Laatste waarneming: {dateAG}</p>
            </Card2>
            <Card3>
              <h2 className={styles.title}>
                Bouwen op water 
              </h2>
              <p>Samenwerkingsverband tussen Rainup en het Scheepvaartmuseum.</p>
            </Card3>
          </main>
        </>
      )
      }
    </>
  );
}

export default Dashboard;
