import React from "react";
import styled from 'styled-components';

const Wrapper = styled.div`
  flex-grow: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoaderImg = styled.img`
  width: 40px;
  height: 40px;
`;

const Loading = () => (
  <Wrapper>
    <div>Pagina wordt geladen</div>
    <LoaderImg src="./loader.gif" alt="loading" />
  </Wrapper>
);

export default Loading;