import React, { useEffect } from 'react';
import styles from '../styles/Home.module.css';
import { useParams } from "react-router-dom";
import Gif from '../components/gif';
import {
  withAuthenticator,
  Button
} from "@aws-amplify/ui-react";

const QR = ({ user }) =>{
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={styles.main}>
      <Gif image='../success-5.gif'/>
      <p className={styles.description}>
          QR id 👉️ {params.id}<br/>
          User id 👉️ {user.username}
      </p>
      <Button>Koppel systeem</Button>
    </main>
  );
}
export default withAuthenticator(QR);

