import "@aws-amplify/ui-react/styles.css";
import './styles/globals.css'
import './styles/styles.css';
import styles from './styles/Home.module.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import QR from "./pages/qr";
import styled from 'styled-components';

const Container = styled.div`
  display: block;
  margin: 0 auto;
`;

// const Container = styled.div`
//   display: block;
//   margin: 0 auto;
//   margin-left: 30%;
//   margin-right: 30%;

//   @media (max-width: 767px) {
//     margin-left: 8%;
//     margin-right: 8%;
//   }
// `;

function App() {
  return (
    <div className="App">
      <Container>
        <div className={styles.box2}>
          <img src='RainupScheepvaart.jpg' alt='logo rainup en scheepvaartmuseum' />
        </div>
        <Router>
          {/* <Navbar /> */}
          <Routes>
            <Route path='/' element={<Dashboard/>} />
            <Route path='/qr/:id' element={<QR/>} />
          </Routes>
        </Router>
      </Container>
    </div>
  );
}

export default App;